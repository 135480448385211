"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isDate = exports.isUrl = exports.toNumber = exports.isObjectNotEmpty = exports.isObject = exports.isFunction = exports.isArrayNotEmpty = exports.sArray = exports.isBoolean = exports.isInt = exports.isNumber = exports.isStringNotEmpty = exports.isString = void 0;
function isString(variable) {
    return typeof variable === 'string' || variable instanceof String;
}
exports.isString = isString;
function isStringNotEmpty(variable) {
    return isString(variable) && variable.length > 0;
}
exports.isStringNotEmpty = isStringNotEmpty;
function isNumber(variable) {
    return (typeof variable === 'number' || variable instanceof Number) && !isNaN(variable);
}
exports.isNumber = isNumber;
function isInt(variable) {
    if (!isNumber(variable))
        return false;
    if (isNaN(variable))
        return false;
    let n = parseFloat(variable);
    return (0 | n) === n;
}
exports.isInt = isInt;
function isBoolean(variable) {
    return typeof variable === 'boolean' || variable instanceof Boolean;
}
exports.isBoolean = isBoolean;
function sArray(variable) {
    return Array.isArray(variable);
}
exports.sArray = sArray;
function isArrayNotEmpty(variable) {
    return Array.isArray(variable) && variable.length > 0;
}
exports.isArrayNotEmpty = isArrayNotEmpty;
function isFunction(variable) {
    return typeof variable === 'function' || variable instanceof Function;
}
exports.isFunction = isFunction;
function isObject(variable) {
    return variable != null && (typeof variable === 'object' || variable instanceof Object) && !(variable instanceof Date);
}
exports.isObject = isObject;
function isObjectNotEmpty(obj) {
    return isObject(obj) && Object.keys(obj).length > 0;
}
exports.isObjectNotEmpty = isObjectNotEmpty;
function toNumber(variable, defaultValue) {
    if (variable === null || variable === undefined)
        return defaultValue;
    if (isNaN(variable))
        return defaultValue;
    if (isNumber(variable))
        return variable;
    let n = Number(variable);
    return isNaN(n) ? defaultValue : n;
}
exports.toNumber = toNumber;
function isUrl(str) {
    return isString(str) && (/^https?:\/\//.test(str));
}
exports.isUrl = isUrl;
function isDate(variable) {
    return variable instanceof Date || Object.prototype.toString.call(variable) === '[object Date]';
}
exports.isDate = isDate;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.diffHHmmss = exports.diffHHmm = exports.diffDateOnly = exports.diffDateTimeSkipSecond = exports.diffDateTime = void 0;
/**
 * @returns true if date1 and date2 are different otherwise false
 */
function diffDateTime(date1, date2, options = {}) {
    if (!date1 && !date2)
        return false;
    if ((date1 && !date2) || (!date1 && date2))
        return true;
    // 2022-06-09T23:20:06.000Z
    let d1 = new Date(date1).toISOString();
    let d2 = new Date(date2).toISOString();
    if (options === null || options === void 0 ? void 0 : options.skipTime) {
        // chỉ so sánh ngày, bỏ qua giờ
        let _d1 = d1.substring(0, 10);
        let _d2 = d2.substring(0, 10);
        return _d1 != _d2;
    }
    else {
        if (options === null || options === void 0 ? void 0 : options.skipSecond) {
            // so sánh ngày giờ nhưng chỉ lấy đến phút, bỏ qua giây
            let _d1 = d1.substring(0, 16);
            let _d2 = d2.substring(0, 16);
            return _d1 != _d2;
        }
        // Bình thường nhập trên form thì chả ai nhập đến milli giây, nên mặc định là bỏ qua milli giây.
        // Nếu cần so sánh cả milli giây thì truyền vào rõ ràng skipMilliSecond = false.
        let skipMilliSecond = true;
        if (typeof (options === null || options === void 0 ? void 0 : options.skipMilliSecond) == 'boolean') {
            skipMilliSecond = options.skipMilliSecond;
        }
        if (skipMilliSecond) {
            let _d1 = d1.substring(0, 19);
            let _d2 = d2.substring(0, 19);
            return _d1 != _d2;
        }
        // so sánh đầy đủ ngày giờ
        return d1 != d2;
    }
}
exports.diffDateTime = diffDateTime;
function diffDateTimeSkipSecond(date1, date2) {
    return diffDateTime(date1, date2, { skipSecond: true });
}
exports.diffDateTimeSkipSecond = diffDateTimeSkipSecond;
/**
 * Only diff date, skip time
 * @returns true if date1 and date2 are different otherwise false
 */
function diffDateOnly(date1, date2) {
    return diffDateTime(date1, date2, { skipTime: true });
}
exports.diffDateOnly = diffDateOnly;
/**
 * Only diff hours and minutes, skip seconds, skip date
 * @returns true if date1 and date2 are different otherwise false
 */
function diffHHmm(date1, date2) {
    if (!date1 && !date2)
        return false;
    if ((date1 && !date2) || (!date1 && date2))
        return true;
    // 2022-06-09T23:20:06.000Z
    let d1 = new Date(date1).toISOString();
    let d2 = new Date(date2).toISOString();
    // chỉ so sánh HH:mm
    let _d1 = d1.substring(11, 16);
    let _d2 = d2.substring(11, 16);
    return _d1 != _d2;
}
exports.diffHHmm = diffHHmm;
/**
 * Only diff hours, minutes and seconds, skip date
 * @returns true if date1 and date2 are different otherwise false
 */
function diffHHmmss(date1, date2) {
    if (!date1 && !date2)
        return false;
    if ((date1 && !date2) || (!date1 && date2))
        return true;
    // 2022-06-09T23:20:06.000Z
    let d1 = new Date(date1).toISOString();
    let d2 = new Date(date2).toISOString();
    // chỉ so sánh HH:mm:ss
    let _d1 = d1.substring(11, 19);
    let _d2 = d2.substring(11, 19);
    return _d1 != _d2;
}
exports.diffHHmmss = diffHHmmss;
